{
  "domain": "dev-auth.ocadohub.com",
  "clientId": "CiJHddi9MI89GLZZH7iJYoJPubeUQsUn",
  "authorizationParams": {
    "audience": "https://commercial.ocadohub.com/v1",
    "scope": "openid profile email offline_access"
  },
  "apiUri": "https://dev-commercial.ocadohub.com",
  "errorPath": "/error"
}
