import { Observable, catchError, throwError } from "rxjs";

import { Subject } from 'rxjs';
import { map } from 'rxjs';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-grids';
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { userRoles } from "../../../shared/models/user-role.model";
import { StateData } from "../../../shared/models/roles.model.inteface"

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root',
})
export class OrlRoleService extends Subject<DataStateChangeEventArgs> {
  protected resourcePath = 'plan/roles';
  protected resourceUserPath = 'plan/users';
  protected planApiUrl = environment.baseApi.plan;
  constructor(private http: HttpClient) {
    super();
  }
  public execute(state: any, query: any): void {
    this.getAllData(state, query).subscribe(x => super.next(x as DataStateChangeEventArgs));
  }
  /** GET all data from the server */
  getAllData(state: any, action: any): Observable<any> {

    return this.http.get<userRoles[]>(`${this.planApiUrl}/${this.resourcePath}`).pipe(
      map((response: any[]) => {
        console.log(response)
        const transformedData = response.map(item => ({
          id: item.id,
          rolename: item.roleName,
          roletype: item.roleType,
          description: item.description,
          isactive: item.isActive,
          ocadorolelist: item.ocadoRoleList,
          scopename: item.scopeName,
          isdeleted: item.isDeleted
        }));
        // Return the result along with the count of total records
        return {
          result: transformedData, // Transformed data
          count: transformedData.length
        };
      })
    );
  }

  /** POST: add a new record  to the server */
  public addRole(state: DataSourceChangedEventArgs): Observable<userRoles> {
    console.log(state);
    let newData: userRoles
    if (state.data) {
      const data = state.data as StateData;

      // Create a new object with the existing state data and additional fields
      newData = {
        rolename: data.rolename,
        roletype: data.roletype,
        description: data.description,
        isactive: true,
        isdeleted: false, // Assuming this field is always true for new roles
        ocadorolelist: '', // You can set this field based on your requirement
        scopename: data.scopename,

      };
    }
    else {
      const data = state as any;
      console.log(data[0])
      newData = {
        rolename: data[0].rolename,
        roletype: data[0].roletype,
        description: data[0].description,
        isactive: true,
        isdeleted: false, // Assuming this field is always true for new roles
        ocadorolelist: '', // You can set this field based on your requirement
        scopename: data[0].scopename,

      };

    }
    console.log(newData);
    const data = state.data as StateData;
    const payload={
      roleName: data.rolename,
      roleType: data.roletype,
      description: data.description,
      scopeName: data.scopename,
      isActive: true,
      isDeleted: false, 
      ocadoRoleList:''
      }
    return this.http.post<userRoles>(`${this.planApiUrl}/${this.resourcePath}`, payload)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.statusText === 'Created') {

            console.log('Role added successfully!');
          } else {
            // Handle other errors
            console.error('Error adding role:', error);
          }
          return throwError(error);
        })
      );
  }
  getallroles() {
    return this.http.get(`${this.planApiUrl}/${this.resourcePath}`);
  }

  getAllroles():Observable<any>{
    return this.http.get(`${this.planApiUrl}/${this.resourcePath}`);
  }

  public getRolesByScope(scopeName:any){
    return this.http.get(`${this.planApiUrl}/plan/roles/scope-name/${scopeName}`,);
  }
  updateRole(role:any, state: DataSourceChangedEventArgs): Observable<userRoles> {
    const dataObject = state.data as { id: string, [key: string]: any };
    const { id } = dataObject;

    return this.http.put(`${this.planApiUrl}/${this.resourcePath}/${id}`, role);
  }

  public login(id: string): void {
    console.log('orl-role-service:login:start');

    this.http.patch(`${this.planApiUrl}/${this.resourceUserPath}/${id}/lastLogin`, null).subscribe({
      next: (response) => {
        console.log('PATCH response', response);
      },
      error: (error) => {
        console.error('Error', error);
      }
    });
  }
}
