import { OcadoEnvironment } from "../app/shared/shared.types";
import config from '../auth_config_dev.json';

const { domain, clientId, authorizationParams: { audience, scope }, apiUri, errorPath } = config as {
  domain: string;
  clientId: string;
  authorizationParams: {
    audience?: string;
    scope?: string;
  },
  apiUri: string;
  errorPath: string;
};

export const environment = {
  production: false, 
  gcpProjectId: 'orl-dev-coml-prj01',
  baseApi: {
    case: 'https://dev-commercial.ocadohub.com',
    document: 'https://dev-commercial.ocadohub.com',
    notification: 'https://dev-commercial.ocadohub.com',
    plan: 'https://dev-commercial.ocadohub.com'  
  },
  environment: OcadoEnvironment.DEV,
  auth: {
    domain,
    clientId,
    authorizationParams: {
      ...(audience && audience !== 'YOUR_API_IDENTIFIER' ? { audience } : null),
      scope: scope,
      redirect_uri: window.location.origin,
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`],
  },
  box: {
      folderId: 275024932166,
      folderName: "DEV Ocado Retail"
  }
};
