import { Injectable } from "@angular/core";
import { CommercialUser } from "../shared/models/commercial-user.model";
import { AuthService, User } from "@auth0/auth0-angular";
import { Store } from "@ngrx/store";
import { combineLatest, firstValueFrom, forkJoin, map, Observable, tap } from "rxjs";
import { selectUser } from "../store/authentication/auth.selector";
import { setUser } from "../store/authentication/auth.actions";
import { jwtDecode } from "jwt-decode";
import { DecodedToken } from "../shared/interfaces/decoded-token.interface";
import { OrlUserservice } from "./backend-services/orl-plan-services/orl-plan-user.service";
import { OrlRoleService } from "./backend-services/orl-plan-services/orl-role-service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class CommercialService {

    constructor(
        private auth: AuthService,
        private store: Store,
        private userService: OrlUserservice,
        private roleService: OrlRoleService,
        private router: Router,
    ) {
        this.initializeUser();
    }

    private initializeUser(): void {
        const token$ = this.auth.getAccessTokenSilently().pipe(
            map(token => {
                if (token) {
                    const decodedToken: any = this.decodeToken(token);
                    console.log('CommercialService:decodedToken:=', decodedToken);
                    return {
                        scope: decodedToken.scope,
                        token
                    };
                }
                return { applications: '', scope: '', token: null };
            })
        );
        const user$ = this.auth.user$.pipe(
            map(user => {
                console.log('CommercialService:Auth0User:=', user);

                return ({
                    id: user['user_uid'],
                    name: user?.name,
                    userName: user['username'],
                    email: user?.email,
                    company_id: user['company_id'],
                    company_name: user['company_name'],
                    applications: user['applications'],
                    isStaff: Boolean(user['is_staff']),
                    picture: user.picture
                })
            })
        );

        combineLatest([token$, user$]).pipe(
            map(([tokenData, user]) => {
                console.log('CommercialService:tokenData:=', tokenData);
                console.log('CommercialService:user:=', user);
                if (user) {
                    return new CommercialUser(
                        user.id,
                        user.name,
                        user.userName,
                        user.email,
                        user.company_id,
                        user.company_name,
                        user.applications?.split(' ') ?? [],
                        tokenData.scope,
                        tokenData.scope.split(' '),
                        user.isStaff ? 'Internal' : 'External',
                        user.picture
                    );
                }
                return null;
            }),
            tap(user$ => {
                if(!user$.commercialAllowed() || !user$.commercialScopeName()) {
                    console.log('No access to commercial application or with minimum scopes');
                    this.router.navigate(['unauthorised']);
                    return;
                }
                this.loadCurrentUserAndRoles(user$);
                if (user$) {
                    this.store.select(selectUser).pipe(
                        map(storedUser => {
                            if (!storedUser) {
                                this.store.dispatch(setUser({ user$ }));
                            }
                        })
                    ).subscribe();
                }
            })
        ).subscribe(result => {
            console.debug('initializeUser:=', result);
            result.applications
        });
    }

    public getToken(): Observable<string> {
        return this.auth.getAccessTokenSilently();
    }

    public getUser(): Observable<CommercialUser | null> {
        return this.store.select(selectUser).pipe(
            map(user => {
                const mappedUser = this.mapUserToCommercialUser(user);
                return mappedUser;
            })
        );
    }

    private decodeToken(token: string): DecodedToken {
        try {
            return jwtDecode<DecodedToken>(token);
        } catch (error) {
            console.error('Error decoding token:', error);
            throw new Error('Invalid token');
        }
    }

    private mapUserToCommercialUser(user: User | null): CommercialUser | null {
        if (!user) return null;
        // DEBUG
        // console.log('CommercialService:mapUserToCommercialUser.user=', user);
        const commercialUser = new CommercialUser(
            user['id'],
            user.name,
            user['userName'],
            user.email,
            user['companyId'],
            user['companyName'],
            user['applications'] ?? [],
            user['scope'],
            user['scope'].split(' '),
            user['userType'],
            user.picture
        );
        // DEBUG
        // console.log('CommercialService:mapUserToCommercialUser.commercialUser=', commercialUser);
        return commercialUser;
    }

    private loadCurrentUserAndRoles(data:any){
        forkJoin({
            roles: this.roleService.getAllroles(),
            currentUser: this.userService.getUserById(data?.id),
        }).subscribe(({ roles, currentUser}) => {
           const roleId=this.findRoleIdByScope(roles, data?.scopes);

            if (currentUser === null) {
                this.createUser(data, roleId);
            }else {

                this.updateUserLastLogin(data?.id)

                if(!currentUser.roles.includes(roleId)){
                    roles={
                        roleIds: [...currentUser.roles, roleId]
                    }
                    this.addUserRoles(data?.id, roles);
                }
            }
        })
    }

    private createUser(data:any, roleId:string){
        const newUser = {
            "id": data?.id,
            "name": data?.name,
            "email": data?.email,
            "userType": data?.userType,
            "roles": [roleId],
            "companyId": parseInt(data?.companyId || '0', 10),
            "isActive": true
        }
        this.userService.createUser(newUser).subscribe(result => {
            console.log(result);
        });
    }

    private updateUserLastLogin(userId:string){
        this.userService.login(userId).subscribe();
    }

    private findRoleIdByScope(roles:any, scopes:any[]){
        return roles.find(role=> scopes?.includes(role.scopeName))?.id
    }

    private addUserRoles(userId:string , roles:any){
        this.userService.addUserRoles(userId,roles).subscribe();
    }

}
