import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpOptions } from '../../shared/config/shared.types';
import { PaginatedResponse } from '../../shared/types/paginatedResponse';
import { MessageResponse } from '../../shared/types/messageResponse';

@Injectable({
  providedIn: 'root'
})
export abstract class HttpApiService<T = any, L = PaginatedResponse<T>, D = MessageResponse> {
  protected proxyKey = 'plan';
  protected productionApiUrl = environment.baseApi.plan;
  protected defaultOptions: HttpOptions = {};
  protected abstract resourcePath: string;

  public constructor(protected readonly http: HttpClient) {
  }

  /**
   * Fetches a list of resource.
   * @param path
   * @param options
   */
  public list(path: string = '', options?: HttpOptions): Observable<L> {
    return this.http.get<L>(this.buildAction(path), this.buildOptions(options));
  }

  /**
   * Gets a resource from their id.
   * @param id
   * @param path
   * @param options
   */
  public find(id: number | string, path: string = '/{:id}', options?: HttpOptions): Observable<T> {
    return this.http.get<T>(this.buildAction(path, { id }), this.buildOptions(options));
  }

  /**
   * Makes a post request to create a resource.
   * @param body
   * @param path
   * @param options
   */
  public create(body: any, path: string = '', options?: HttpOptions): Observable<T> {
    return this.http.post<T>(this.buildAction(path), body, this.buildOptions(options));
  }
  public search<T>(url: string, params?: string, options?: HttpOptions): Observable<T> {
    const actionParams = params ? this.buildAction(url, { params }) : this.buildAction(url);
    const requestOptions = this.buildOptions(options);
    return this.http.get<T>(actionParams, requestOptions);
  }
  /**
   * Makes an save request.
   * @param id
   * @param body
   * @param path
   * @param options
   */
  public update(id: number | string, body: any, path: string = '/{:id}', options?: HttpOptions): Observable<T> {
    let req;
    if (body instanceof FormData) {
      body.append('_method', 'PATCH');
      req = this.http.post<T>(this.buildAction(path, { id }), body, this.buildOptions(options));
    } else {
      console.log('update:2');
      req = this.http.patch<T>(this.buildAction(path, { id }), body, this.buildOptions(options));
    }

    return req;
  }

  /**
   * Handles PUT requests.
   * @param id
   * @param body
   * @param path
   * @param options
   */
  public put(id: number | string, body: any, path: string = '/{:id}', options?: HttpOptions): Observable<T> {
    let req;

    if (body instanceof FormData) {
      body.append('_method', 'PUT');
      req = this.http.post<T>(this.buildAction(path, { id }), body, this.buildOptions(options));
    } else {
      req = this.http.put<T>(this.buildAction(path, { id }), body, this.buildOptions(options));
    }

    return req;
  }

  /**
   * Makes a delete request.
   * @param id
   * @param path
   * @param options
   */
  public delete(id: number | string, path: string = '/{:id}', options?: HttpOptions): Observable<D> {
    return this.http.delete<D>(this.buildAction(path, { id }), this.buildOptions(options));
  }

  /**
   * Makes the url of the request.
   * @param path
   * @param params
   * @param fromBaseUri
   * @protected
   */
  protected buildAction(path: string, params?: { [key: string]: string | number }, fromBaseUri: boolean = false): string {
    params = params ? params : {};

    return Object.keys(params).reduce((url, paramKey) => {
      // @ts-ignore
      return url.replace(new RegExp(`\{\:${paramKey}\}`, 'ig'), params[paramKey]);
      // tslint:disable-next-line: max-line-length
    }, [
      this.getApiBasePath().replace(/^\/|\/$/g, ''),
      ...(fromBaseUri ? [] : [
        this.resourcePath.replace(/^\/|\/$/g, '')
      ]),
      path.replace(/^\/|\/$/g, '')
    ].join('/').replace(/\/$/g, ''));
  }

  /**
   * Build options for teh request.
   * @param options
   * @protected
   */
  protected buildOptions(options?: HttpOptions): HttpOptions {
    // nothing set, use defaults (even if undefined)
    if (!options) {
      return this.defaultOptions;
    }

    // options set but no defaults, use what was set
    if (!this.defaultOptions) {
      return options;
    }

    return Object.assign({}, this.defaultOptions, options, {
      headers: Object.assign(
        {},
        this.defaultOptions['headers'] ? this.defaultOptions['headers'] : {},
        options['headers'] ? options['headers'] : {}
      ),
      params: Object.assign(
        {},
        this.defaultOptions['params'] ? this.defaultOptions['params'] : {},
        options['params'] ? options['params'] : {}
      )
    }) as HttpOptions;
  }

  protected getApiBasePath(): string {
    return `${this.productionApiUrl}/`;
  }
}

