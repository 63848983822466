export class CommercialUser {
    /** 
     * The list of applications a user is authorised for.
     * Example: commercial
     * @type {string[]}
     */
    applications: string[];

    /** 
     * The unique user id.
     * @type {string}
     */
    id: string;

    /** 
     * The user's firstname and surname.
     * @type {string}
     */
    name: string;

    /** 
     * The user's username.
     * @type {string}
     */
    userName: string;

    /** 
     * The user's email address.
     * @type {string}
     */
    email: string;

    /** 
     * The user's company id.
     * @type {number}
     */
    companyId: number;

    /** 
     * The user's company name.
     * @type {string}
     */
    companyName: string;

    /** 
     * The user's default scope a user is authoirsed for.
     * @type {string}
     */
    scope: string;

    /** 
     * The user's list of all scopes a user is authoirsed for.
     * @type {string}
     */
    scopes: string[];

    /** 
     * The user type can be External or Internal.
     * @type {string}
     */
    userType: string;

    /** 
     * A link to the user's profile picture.
     * @type {string}
     */
    picture: string;

    /** 
     * Creates an instance of a Commercial User
     * @param {string} id - The unique user id.
     * @param {string} name - The user's firstname and surname.
     * @param {string} userName - The user's username
     * @param {string} email - The user's email address.
     * @param {number} companyId - The user's company id.
     * @param {string} companyName - The user's company name.
     * @param {string[]} applications - The list of applications a user is authorised for.
     * @param {string} scope - The user's default scope a user is authoirsed for.
     * @param {string[]} scopes - The user's list of all scopes a user is authoirsed for.
     * @param {string} userType - The user type can be External or Internal.
     * @param {string} picture - A link to the user's profile picture.
     */
    constructor(id: string, name: string, userName: string, email: string, companyId: number, companyName: string, applications: string[], scope: string, scopes: string[], userType: string, picture: string) {
        this.id = id;
        this.name = name;
        this.userName = userName;
        this.email = email;
        this.companyId = companyId;
        this.companyName = companyName;
        this.applications = applications;
        this.scope = scope;
        this.scopes = scopes;
        this.userType = userType,
            this.picture = picture
    }

    /**
     * Determines if the specified scope is included in the user's authorized scope list.
     * @param {string} scopeName - The name of the scope to check.
     * @returns {boolean} - Returns true if the user has the specified scope; otherwise, false.
     */
    private _hasScope(scopeName: string): boolean {
        if (!this.scopes || this.scopes.length == 0) return false;
        return this.scopes.includes(scopeName);
    }


    /**
     * Checks if the 'commercial' application is included in the user's authorized applications.
     * @returns {boolean} - True if 'commercial' is an authorized application; otherwise, false.
     */
    commercialAllowed(): boolean {
        return this.applications.includes('commercial');
    }

    /**
     * Retrieves the first scope name that starts with 'commercial:'.
     * @returns {string | undefined} - The scope name starting with 'commercial:', or undefined if none found.
     */
    commercialScopeName(): string | undefined {
        return this.scopes.find(f => f.startsWith('commercial:'));
    }

    /**
     * Checks if the user has all specified scopes.
     * @param {string[]} scopeNames - The list of scope names to check.
     * @returns {boolean} - True if the user has all specified scopes; otherwise, false.
     */
    hasAllScopes(scopeNames: string[]): boolean {
        return scopeNames.every(scopeName => this._hasScope(scopeName));
    }

    /**
     * Checks if the user has any of the specified scopes.
     * @param {string[]} scopeNames - The list of scope names to check.
     * @returns {boolean} - True if the user has at least one of the specified scopes; otherwise, false.
     */
    hasAnyScope(scopeNames: string[]): boolean {
        return scopeNames.some(scopeName => this._hasScope(scopeName));
    }

    /**
     * Checks if the user has 'commercial:admin' scope.
     * @returns {boolean} - True if the user is an admin; otherwise, false.
     */
    isAdmin(): boolean {
        return this._hasScope('commercial:admin');
    }

    /**
     * Checks if the user has 'commercial:buyer' scope.
     * @returns {boolean} - True if the user is a buyer; otherwise, false.
     */
    isBuyer(): boolean {
        return this._hasScope('commercial:buyer');
    }

    /**
     * Checks if the user has 'commercial:buyingdirector' scope.
     * @returns {boolean} - True if the user is a buying director; otherwise, false.
     */
    isBuyingDirector(): boolean {
        return this._hasScope('commercial:buyingdirector');
    }

    /**
     * Checks if the user has 'commercial:cco' scope.
     * @returns {boolean} - True if the user is a Chief Commercial Officer (CCO); otherwise, false.
     */
    isCCO(): boolean {
        return this._hasScope('commercial:cco');
    }

    /**
     * Checks if the user has 'commercial:planowner' scope.
     * @returns {boolean} - True if the user is a plan owner; otherwise, false.
     */
    isPlanOwner(): boolean {
        return this._hasScope('commercial:planowner');
    }

    /**
     * Checks if the user has 'commercial:readonly' scope.
     * @returns {boolean} - True if the user has read-only access; otherwise, false.
     */
    isReadOnly(): boolean {
        return this._hasScope('commercial:readonly');
    }

    /**
     * Checks if the user has 'commercial:supplier' scope.
     * @returns {boolean} - True if the user is a supplier; otherwise, false.
     */
    isSupplier(): boolean {
        return this._hasScope('commercial:supplier');
    }

    /**
     * Checks if the user has 'commercial:supplier:user' scope.
     * @returns {boolean} - True if the user is a supplier user; otherwise, false.
     */
    isSupplierUser(): boolean {
        return this._hasScope('commercial:supplier:user');
    }

    /**
     * Checks if the user has 'commercial:tradingmanager' scope.
     * @returns {boolean} - True if the user is a trading manager; otherwise, false.
     */
    isTradingManager(): boolean {
        return this._hasScope('commercial:tradingmanager');
    }
}