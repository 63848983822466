import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY } from './auth.constants';
import { AppState, AuthState } from './auth.state';

// export const selectFeature = createFeatureSelector<State>('key');
// export const selectAuth = createSelector(selectFeature, state => state.authToken);

// export const selectAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);
// export const selectUser = createSelector(selectAuthState, (state: AuthState) => state.user$);

export const selectAuthState = (state: AppState) => state.auth;
export const selectUser = createSelector(selectAuthState,(state: AuthState) => state.user$);